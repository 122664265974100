.freshers__hero {
  background-image: linear-gradient(
    to right bottom,
    #240730,
    #1e092c,
    #190927,
    #150922,
    #11081c
  );
  min-height: 100vh;
  display: grid;
  place-items: center;
  color: var(--clr-white);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;

    width: var(--container-width);
    max-width: var(--container-max-width);

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      margin-top: 100px;
    }
  }

  &__img {
    width: 400px;
    position: relative;
    top: 20px;

    @media screen and (max-width: 760px) {
      width: 250px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 760px) {
      margin-bottom: 50px;
    }
  }

  &__heading {
    background: linear-gradient(
      111.6deg,
      rgb(225, 15, 15) 0.4%,
      rgb(34, 14, 215) 100.3%
    );
    background-clip: text;

    font-size: 7rem;
    color: transparent;

    @media screen and (max-width: 1000px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 4.2rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 3.5rem;
    }
  }

  &__desc {
    text-align: center;
    color: rgba(214, 214, 214, 0.826);
  }

  &__register-btn {
    margin-top: 10px;
    padding-block: 10px;
  }
}
