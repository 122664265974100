body
{
    //background: #2691d9;
    background: linear-gradient(120deg, #2980b9, #8e44ad);
}

.login-anakhronos{
    margin:0;
    padding:0;
    height: 100vh;
    overflow: hidden;
  } 

  label {
    font: 1rem 'Fira Sans', sans-serif;
  }

  input {
    margin: 0.4rem;
}
  .center-anakhronos{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .5rem 0.1rem;
    max-width: 400px;
    background: white;
    border-radius: 10px;
  }
  
  .center-anakhronos H1{
    text-align: center;
    padding: 20px 0 20px 0;
    font-size: 25px;
    border-bottom: 1px solid silver;
   
  }
  .center-anakhronos H3{
    text-align: center;
    padding: 5px 5px 5px 5px;
    font-size: 13px;
    border-bottom: 1px solid silver;
   
  }
  
  .btn
  {
    position: relative;
    margin: 0 auto;
  }
  .center-anakhronos form{
    padding: 0 40px;
    box-sizing: border-box;
  }
  
  form .txt_field{
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
  }
  .google-login {
    background-color: #8A1615;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    }
    .google-icon
    {
      top: 2.5px;
      left: 50px;
      position: relative;
    }
    
   
    .google-login span
    {
      margin-left: 55px;
    }
    .google-login:hover {
    background-color: #5f2020;
    color: #fff;
    }
  .txt_field input{
    width: 100%;
    padding: 0.5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
  }
  
  .txt_field label{
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
  }
  
  .txt_field span::before{
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #2691d9; 
    transition: .5s;
  }
  
  .txt_field input:focus ~ label,
  .txt_field input:valid ~ label{
    top: -5px;
    color: #2691d9;
    
  }
  
  .txt_field input:focus ~ span::before,
  .txt_field input:valid ~ span::before{
    width: 100%; 
  }
  
  .pass{
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
    transition: .5s;
  }
  
  .pass:hover{
    text-decoration: underline;
    color: #2691d9;
    transition: .5s;
  }
  
  input[type="submit"]{
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #2691d9;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    transition: .5s;
  }
  
  input[type="submit"]:hover{
    border:solid 10px #2691d9;
    transition: .5s;
  }
  
  .signup_link{
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    color: #666666
  }
  
  .signup_link a{
    color: #2691d9;
    text-decoration: none;
    transition: .5s;
  }
  
  .signup_link a:hover{
    text-decoration: underline;
    transition: .5s;
    color: #666666;
  }
  .buttons
  {
    display: flex;
  }
  .buttons a
  {
    background-color: #8A1615 ;
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 10px;
  }

  @media (max-width: 768px)
  {
    .center-anakhronos H1{
        font-size: 20px;      
      }

      .center-anakhronos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 1rem;
        max-width: 400px;
        background: white;
        border-radius: 10px;
        margin: 50px 0;
      }
  }

  @media (max-width : 720px)
  {
    .btn
  {
    padding: 3.5px 3.5px;
    position: relative;
    margin: 0 auto;
  }
  .buttons a
  {
    background-color: #8A1615 ;
    color: white;
    padding: 4px 7px;
    text-decoration: none;
    border-radius: 10px;
  }
  }
