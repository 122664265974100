@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800&display=swap');

.contact {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(60deg, #925ecac3, #32b0be, #ad58c7);
    background-size: 600% 600%;
    animation: bganimation 10s infinite linear;

    @keyframes bganimation {
        0% {
          background-position: 0% 85%;
        }
      
        50% {
          background-position: 100% 20%;
        }
      
        100% {
          background-position: 0% 85%;
        }
      }

    .headerinfo {
        padding: 70px 0 30px 0;

        @media (max-width: 860px) {
            text-align: center;
        }
    }

    .form {
        padding: 70px 0 30px 0;

        input,
        textarea {
            width: 100%;
            background-color: transparent;
            border: 0px;
            outline: none;
            box-shadow: none;
            border-bottom: 1px solid #707070;
            height: 30px;
            margin-bottom: 30px;
        }

        a
        {
            font-size: 20px;
            text-decoration: none;
            color: black;
        }

        label
        {
            font-size: 18px;
        }
        textarea {
            min-height: 100px;
        }

        @media (max-width: 860px) {
            padding: 30px 0;
        }
    }

    .submitWrapper {
        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 50px;
        }

        input {
            border: 1px solid #E52F8A;
            background-color: #ff0156cc;
            width: 100%;
            padding: 15px;
            outline: none;
            color: #fff;
            cursor: pointer;

            &:hover {
                background-color: #580cd2;
                border: 1px solid #7620ff;
                color: #fff;
            }

            @media (max-width: 991px) {
                margin: 0 auto;
            }
        }
    }

    .col-xs-12 {
        width: 100%;
    }

    @media (min-width: 576px) {
        .col-sm-12 {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .col-md-6 {
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .col-lg-6 {
            width: 50%;
        }
    }

    .font13 {
        font-size: 13px;
        line-height: 20px;
        font-family: 'Poppins', sans-serif;
        color: #363636;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .font20 {
        font-size: 20px;
        line-height: 30px;
        font-family: 'Poppins', sans-serif;
        color: #2c2c2c;
    }

    .font40 {
        font-size: 40px;
        line-height: 48px;
        font-family: 'Poppins', sans-serif;
        color: rgb(27, 27, 27);
    }

}