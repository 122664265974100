@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;600&display=swap');

$transition-time: 3.0s;
.app__section {
  overflow: hidden;
}
.merchandise {
padding-top: 2rem;
padding-bottom:2rem;
.card{
	position: relative;
	height: 400px;
	width: 100%;
	margin: 10px 0;
	transition: ease all $transition-time;
	perspective: 1200px;
	&:hover{
		.cover{
			transform: rotateX(0deg) rotateY(-180deg);
			&:before{
				transform: translateZ(30px);
			}
			&:after{
				background-color: black;
			}
			h1{
				transform: translateZ(100px);
			}
			.price{
				transform: translateZ(60px);
			}
			a{
				transform: translateZ(-60px) rotatey(-180deg);
			}
		}
	}	
	.cover{
		position: absolute;
		height: 100%;
		width: 100%;
		transform-style: preserve-3d;
  	transition: ease all $transition-time;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		&:before{
			content: '';
			position: absolute;
			border: 5px solid rgba(255,255,255,.5);
			box-shadow: 0 0 12px rgba(0,0,0,.3); 
			top:20px;
			left:20px;
			right:20px;
			bottom:20px;
			z-index: 2;
			transition: ease all $transition-time;
			transform-style: preserve-3d;
			transform: translateZ(0px);
		}
		&:after{
			content: '';
			position: absolute;
			top:0px;
			left:0px;
			right:0px;
			bottom:0px;
			z-index: 2;
			transition: ease all 2.3s;
			background: rgba(0,0,0,.4);
		}
		// &.item-a{
		// 	background-image: url('https://user-images.githubusercontent.com/73426684/231208092-12d1ce64-0d91-4d5d-a11a-d6bd7d04c982.jpg');
		// }
		&.item-b{
			 background-image: url('https://user-images.githubusercontent.com/73426684/231208092-12d1ce64-0d91-4d5d-a11a-d6bd7d04c982.jpg');
			//background-image: url('https://user-images.githubusercontent.com/102872077/232114873-5919ee23-8007-4d59-811e-5b6d960ab9a5.png');
		}
		// &.item-c{
		// 	background-image: url('https://user-images.githubusercontent.com/73426684/231223562-1eb00d4a-b786-41ea-a2b9-9d9ac4988f07.jpg');
		// }
		h1{
			font-weight: 600;
			position: absolute;
			bottom: 55px;
			left: 50px;
			color: white;
			transform-style: preserve-3d;
  		transition: ease all $transition-time;
			z-index:3;
			font-size: 3em;
			transform: translateZ(0px);
		}
		.price{
			font-weight: 200;
			position: absolute;
			top: 55px;
			right: 50px;
			color: white;
			transform-style: preserve-3d;
  		transition: ease all $transition-time;
			z-index:4;
			font-size: 2em;
			transform: translateZ(0px);
		}
	}
	.card-back{
		position: absolute;
		height: 100%;
		width: 100%;
		background: #0b0f08;
		transform-style: preserve-3d;
  	transition: ease all $transition-time;
		transform: translateZ(-1px);
		display: flex;
		align-items: center;
		justify-content: center;
		a{
			transform-style: preserve-3d;
			transition: ease transform $transition-time, ease background .5s;
			transform: translateZ(-1px) rotatey(-180deg);
			background: transparent;
			border: 1px solid white;
			font-weight: 200;
			font-size: 1.3em;
			color: white;
			padding: 14px 32px;
			outline: none;
			text-decoration: none;
			&:hover{
				background-color: white;
				color: #0b0f08;
			}
		}
	}
}
}
.bgcolour_merchandise {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #0635f1c3, #8ca9cc, #3f55e1);
  background-size: 600% 600%;
  animation: bganimation 10s infinite linear;
}
@keyframes bganimation {
  0% {
    background-position: 0% 85%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 85%;
  }
}