$event-primary: rgb(138, 22, 21);
$event-dark-primary: rgb(109, 16, 14);
$event-light-primary: rgba(215, 196, 196, 0.886);
$event-text: #ffffff;
$event-primary-text: #212121;
$event-secondary-text: #757575;
$event-accent: rgba(210, 184, 183, 0.622);

.mega-events {
  &-section {
    padding: 50px 0 100px 0;
  }

  &-heading {
    font-size: 5rem;
    padding: 50px 0;
    text-align: center;
    position: relative;
    z-index: 10;
  }
}

.gradient-bg {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #8320edc3, #8cbecc, #ab72c5);
  background-size: 600% 600%;
  animation: bganimation 10s infinite linear;
}

.timeline {
  position: relative;

  &::before {
    content: '';
    top: 80px;
    background: $event-light-primary;
    width: 10px;
    height: 80%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(10%);
  }
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;

  &:nth-child(even) {
    .timeline-content {
      float: right;
      padding: 40px 30px 10px 30px;

      .date {
        right: auto;
        left: 0;
      }

      &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        left: -15px;
        border-width: 10px 15px 10px 0;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.timeline-content {
  position: relative;
  width: 45%;
  padding: 10px 30px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
  p {
    margin-top: 30px;
  }
  &::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #f5f5f5;
  }
}

.timeline-img {
  width: 30px;
  height: 30px;
  background: $event-primary;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;
}

.mega-events-a {
  background: $event-primary;
  color: $event-text;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.6);

  &:hover,
  &:active,
  &:focus {
    background: darken($event-primary, 10%);
    color: $event-text;
    text-decoration: none;
  }
}

.timeline-card {
  padding: 0 !important;

  p {
    padding: 0 20px;
  }

  a {
    margin-left: 20px;
  }
}

.timeline-item {
  // .timeline-img-header {
  //   // background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
  //   //   url('https://picsum.photos/1000/800/?random') center center no-repeat;
  //   // background-size: cover;
  // }
  .imageEvent {
    img {
      width: 100%;
      height: 220px;
      background-size: cover;
      filter: brightness(80%);
    }
  }
}

.timeline-img-header {
  height: 200px;
  position: relative;
  margin-bottom: 20px;

  h2 {
    font-size: 4rem;
    color: $event-text;
    position: absolute;
    bottom: 5px;
    left: 20px;
  }
}

blockquote {
  margin-top: 30px;
  color: $event-secondary-text;
  border-left-color: $event-primary;
  padding: 0 20px;
}

.date {
  background: $event-accent;
  display: inline-block;
  color: $event-text;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .timeline {
    &::before {
      left: 50px;
      top: 55px;
    }
    .mega-events-heading {
      font-size: 4rem;
      padding: 20px 0px;
    }

    .timeline-img {
      left: 50px;
    }

    .timeline-content {
      max-width: 100%;
      width: auto;
      margin-left: 70px;
      p {
        margin-top: 30px;
      }

      .timeline-img-header {
        h2 {
          font-size: 3rem;
        }
      }
    }

    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          float: none;
        }
      }

      &:nth-child(odd) {
        .timeline-content {
          &::after {
            content: '';
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 30px;
            left: -15px;
            border-width: 10px 15px 10px 0;
            border-color: transparent #f5f5f5 transparent transparent;
          }
        }
      }
    }
  }
}
