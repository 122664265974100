.hero {
  background-image: linear-gradient(
    to right bottom,
    #240730,
    #1e092c,
    #190927,
    #150922,
    #11081c
  );

  min-height: 100vh;
  display: grid;
  place-items: center;
  color: var(--clr-white);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;

    width: var(--container-width);
    max-width: var(--container-max-width);

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      margin-top: 100px;
    }
  }

  &__img {
    max-width: min(400px, 99vw);

    &-container {
      position: relative;
    }

    // @media screen and (min-width: 760px) {
    //   position: absolute;
    //   width: 550px;
    //   z-index: 2;
    //   top: -100px;
    // }
  }

  &__bgimg {
    position: absolute;
    width: min(400px, 99vw);
    max-width: min(400px, 99vw);
    z-index: 1;
    opacity: 0.7;
    top: -30px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      margin-top: 100px;
    }
  }

  &__heading {
    background: linear-gradient(
      111.6deg,
      rgb(225, 15, 15) 0.4%,
      rgb(34, 14, 215) 100.3%
    );
    background-clip: text;

    font-size: 7rem;
    color: transparent;

    @media screen and (max-width: 1000px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 4.2rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 3.5rem;
    }
  }

  &__desc {
    text-align: center;
    color: rgba(214, 214, 214, 0.826);
  }

  &__register-btn {
    margin-top: 10px;
    padding-block: 10px;
  }
}
