.college {
  background-image: linear-gradient(
    to right bottom,
    #240730,
    #1e092c,
    #190927,
    #150922,
    #11081c
  );

  padding: 5rem 0;
  &__wrapper {
    display: flex;
    justify-content: center;
    gap: 3.6rem;
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
   
    img {
      max-width: 500px;
      height: 100%;
      border-radius: 12px;
      transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.02;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      line-height: 1.3;
      text-align: center;
      padding-bottom: 3rem;
      font-size: 6rem;
      background: linear-gradient(
        111.6deg,
        rgb(255, 0, 0) 0.4%,
        rgb(47, 4, 239) 100.3%
      );
      -webkit-background-clip: text;
      //   background: linear-gradient(-90deg, #ff0000, #980eb7, #fb6ddd, #ff0000);
      //   animation: animate 7s linear infinite;
      //   background-size: 400% 100%;
      color: transparent;
    }
    p {
      letter-spacing: 1.5px;
      text-align: center;
      width: 90%;
      color: rgb(195, 193, 193);
    }
  }
}

@media all and (max-width: 900px) {
  .college {
    padding: 1rem;
    &__wrapper {
      flex-direction: column;
    }
    &__left {
      img {
        max-width: 500px;
        height: 100%;
      }
    }
    &__right {
      h1 {
        font-size: 5rem;
      }
    }
  }
}
@media all and (max-width: 500px) {
  .college {
    &__left {
      img {
        max-width: 350px;
        height: 100%;
      }
    }
    &__right {
      h1 {
        padding-bottom: 2rem;
        line-height: 1.2;
        font-size: 3.2rem;
      }
    }
  }
}
