.navbar {
  --nav-link-clr: var(--clr-white);

  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  height: 70px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 0 8px;
  }

  transition: all 0.5s ease-in-out;

  &--shadow {
    background-color: rgba(5, 5, 5, 0.8);
  }

  &--mobile,
  &--show {
    transition: all 0.5s ease-in-out;
    top: 0;
  }

  &--hide {
    transition: all 0.5s ease-in-out;
    top: -70px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--container-width);
    max-width: var(--container-max-width);
    margin: 0 auto;

    transition: all 0.5s ease-in-out;
  }

  &__logo {
    img {
      width: 50px;
      height: 50px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    &--mobile-only {
      gap: 1rem;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    transition: all 0.3s ease;

    &--mobile-only {
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      gap: 3rem;
      width: 100%;
      height: 500px;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.589);
    }

    &-item {
      a {
        position: relative;
        color: var(--nav-link-clr);
        text-transform: capitalize;
        font-weight: 400;

        &,
        &::after,
        &::before {
          transition: all 0.3s ease;
        }

        &::after {
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0%;
          content: '.';
          color: transparent;
          background: var(--nav-link-clr);
          height: 1px;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }

  &__toggle-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
