.app__section footer{
    width: 100% !important; 
}
.footer-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0B031B;
}

.footer-1 {
    display: flex;
    height: 25%;
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    
    justify-content: space-around;
}

.footer-2 {
    height: fit-content;
    width: 100%;
    background-color: #080113;
    justify-content: space-around;
}

.contact-about {
    // margin: 30px;
    padding: 10px;
    width: 50%;
}

.footer-logo img {
    margin-left: 8%;
    margin-bottom: 2%;
    width: 10%;
}

.footer-detail {
    font-size: 0.9em;
    font-family: "Montserrat", sans-serif;
    margin-left: 5%;
    line-height: 150%;
    font-weight: 400;
    color: #838383;
    width: 80%;
}

.contact-us {
    width: 50%;
}

// .contact-us-logo img {
//     -webkit-filter: invert(1);
//     /* fill: #F50136; */
// }
.contact-us-detail{
    
}
.contact-us-detail h1 {
    margin-top: 5%;
    margin-bottom: 5%;
    text-transform: uppercase;
    font-size: 2em;
    color: #ffffff;
}
.contact-us-detail h2 {
    margin-top: 4%;
    margin-bottom: 4%;
    text-transform: uppercase;
    font-size: 1em;
    color: #ffffff;
}
.contact-us-detail h1::before {
    width: 30px;
    content: " ";
    position: absolute;
    border-top: solid 3px #f50136;
    left: 0;
    top: 0;
}

.contact-us-detail h3 {
    margin-top: 1%;
   
    font-size: 0.9em;
    font-weight: 300;
    color: #838383;
}

.contact-us-logo img {
    -webkit-filter: invert(1);
   filter: invert(1);
    width: 10%;
    height: 15%;
    

}
.footer-2 h1{
    // margin: 1%;
    font-size: 0.7em;
    font-weight: 200;
    color: #838383;   
}
// .Qr_footer{

// }
@media only screen and (max-width: 427px) {
    .footer-1 {
        max-width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        // margin-left: auto;
    }
  }