.bgcolour_teams {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #925ecac3, #32b0be, #ad58c7);
  background-size: 600% 600%;
  animation: bganimation 10s infinite linear;
}

@keyframes bganimation {
  0% {
    background-position: 0% 85%;
  }

  50% {
    background-position: 100% 20%;
  }

  100% {
    background-position: 0% 85%;
  }
}

.team-container {
  padding-block: 4rem;

  h1:first-child {
    text-transform: uppercase;
    margin-left: 7rem;
    text-align: center;
    //top: 3.125rem;
    letter-spacing: 0.2rem;
    position: relative;
    padding: 10px 0;
    font-size: 4rem;
    padding-bottom: 1rem;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 35rem;
  height: 42rem;

  .info {
    position: absolute;
    bottom: 30px;
    left: 0;
    color: #ffffff;
    font-family: sans-serif;
    padding-left: 2rem;
    padding-bottom: 2rem;
    transform: translateY(20px);
    opacity: 0;
    transition: all 500ms ease;
  }

  .info h2 {
    font-size: 3rem;
    text-shadow: 2px 2px 5px black;
  }

  .info h3 {
    font-size: 2.2rem;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 5px black;
  }

  .info a {
    color: #ffffff;
    top: 25px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
    border: 2px solid whitesmoke;
    border-radius: 50%;
    padding: 1rem;
    margin-right: 20px;
  }
}

.info a:hover {
  color: rgb(68, 68, 255);
  background: white;
}

.swiper-slide:hover {
  .info {
    transform: translateY(0);
    opacity: 1;
    transition: all 500ms ease;
  }
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }

  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }

  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  // background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3));
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
  position: relative;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controller {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controller .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controller .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controller .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controller .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controller .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controller .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controller .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controller .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
