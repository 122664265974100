.about_wrapper {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #c7465790, #8242a094, #5341ca);
  background-size: 600% 600%;
  animation: bganimation 10s infinite linear;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 20px;
    gap: 2rem;
  }
}
.about_1 {
  width: 50%;

  @media screen and (max-width: 760px) {
    width: 100%;
  }
}
.about_1 h1 {
  margin-bottom: 3%;
  font-size: 2.2em;
}
.about_1 p {
  font-size: 1em;
  font-weight: 400;
}

@keyframes bganimation {
  0% {
    background-position: 0% 85%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 85%;
  }
}
