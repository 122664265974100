@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html,
html:focus-within {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

li {
  list-style: none;
}

body {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
