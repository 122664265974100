@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

.artist {
  padding: 5rem 0;

  h1 {
    text-transform: uppercase;
    margin-left: 7rem;
    //top: 3.125rem;
    text-align: center;
    letter-spacing: 0.2rem;
    position: relative;
    padding: 10px 0;
    font-size: 3rem;
    padding-bottom: 2rem;
  }

  .swiper {
    padding-bottom: 3.5rem;

    .swiper-slide {
      width: auto;
      height: auto;

      .artist-info {
        position: absolute;
        bottom: 15px;
        left: 0;
        color: #ffffff;
        padding-left: 2rem;
        padding-bottom: 2rem;

        span {
          font-size: 25px;
          font-family: 'Poppins', sans-serif;
          text-shadow: 2px 2px 5px black;
          font-weight: 400;
        }
      }
    }

    &__slide {
      transform: translateX(-275px) !important;
      max-width: 395px;
      max-height: 280px;
      object-fit: contain;
      overflow: hidden;
      padding-bottom: 2rem;

      img {
        width: 395px;
        //min-height: 280px;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        object-fit: cover;
      }
    }

    &__arrow {
      position: absolute;
      top: 76%;
      left: 46.5%;
      z-index: 10;

      img {
        width: 85px;
        cursor: pointer;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .gallery {
    .swiper {
      .swiper-slide {
        height: auto !important;
        width: auto !important;
      }

      .swiper-slide img {
        width: auto !important;
        height: auto !important;
        object-fit: contain !important;
      }

      &__slide {
        padding-left: 1rem;
        transform: none !important;

        img {
          max-width: 500px;
          max-height: 280px;
        }
      }

      &__arrow {
        position: absolute;
        top: 80%;
        left: 45%;
        z-index: 10;

        img {
          width: 50px;
          cursor: pointer;
        }
      }
    }
  }
}

.bgcolour_artist {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #8320edc3, #8cbecc, #e13f88);
  background-size: 600% 600%;
  animation: bganimation 10s infinite linear;
}

@keyframes bganimation {
  0% {
    background-position: 0% 85%;
  }

  50% {
    background-position: 100% 20%;
  }

  100% {
    background-position: 0% 85%;
  }
}